import { useState } from 'react';
import { Main } from '/HTML/Main';

function App() {
	return (
		<>
			<Main />
		</>
	);
}

export default App;
